<template>
  <div class="datetime-picker">
    <input v-model.lazy="year" ref="year" class="d f" title="year" type="text" pattern="[0-9]*" input-mode="numeric" @focus="onFocus" @keydown="keydown">
    <span class="sep">-</span>
    <input v-model="month" ref="month" class="d t" title="month" type="text" pattern="[0-9]*" input-mode="numeric" @focus="onFocus" @keydown="keydown">
    <span class="sep">-</span>
    <input v-model="day" ref="day" class="d t" title="day" type="text" pattern="[0-9]*" input-mode="numeric" @focus="onFocus" @keydown="keydown">
    <span class="sep" />
    <input v-model="hour" ref="hour" class="tm t" title="hour" type="text" pattern="[0-9]*" input-mode="numeric" @focus="onFocus" @keydown="keydown">
    <span class="sep">:</span>
    <input v-model="minute" ref="minute" class="tm t" title="minute" type="text" pattern="[0-9]*" input-mode="numeric" @focus="onFocus" @keydown="keydown">
    <span class="sep">:</span>
    <input v-model="second" ref="second" class="tm t" title="second" type="text" pattern="[0-9]*" input-mode="numeric" @focus="onFocus" @keydown="keydown">
    <span v-if="!is24Hour" class="sep"/>
    <input v-if="!is24Hour" v-model="period" ref="period" class="tm p" title="period" type="text" pattern="[ap]m" @focus="onFocus" @keydown="keydownPeriod">
  </div>
</template>

<style scoped lang="scss">
.datetime-picker {
  font-size: 0.9em;
  white-space: nowrap;
  background: var(--input-bgcolor);
  border-radius: var(--br);
  border: 1px solid var(--input-border);
  padding: 0 0.5em;
  
  input {
    min-width: 2.1ex;
    width: 2ex;
    border: none;
    background: transparent;
    padding: 0 0.1em;
    caret-color: transparent;
    text-align: center;
    
    &.f { width: 5ex;}
    &.p { width: 2.53ex;} // the font needs the .02ex to not clip the right side of the m in am pm
    
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    
    &:focus {
//      outline: none;
      background: var(--accent-color);
    }
  }
  
  .sep {
    margin: 0;
  }
}
</style>
 
<script>
export default {
  
  props: {
    value: {
      type: [Date, String],
    default: undefined
    },
    
    is24Hour: {
      type: Boolean,
      default: false
    }
  },
  
  data() {
    return {
      day: undefined,
      month: undefined,
      year: undefined,
      hour: undefined,
      minute: undefined,
      second: undefined,
      period: undefined,
          
      selectedPart: undefined,
      selectedInputCount: 0, // the number of inputs (modifying keystrokes) made to the selected input since its selection
      
      internalValue: undefined,
    }
  },
  
  computed: {
    canSelectPrevPart() {
      return this.parts.indexOf(this.selectedPart) != 0;
    },
    canSelectNextPart() {
      return this.parts.indexOf(this.selectedPart) != (this.parts.length - 1);
    },
    
    parts() {
      let p = [
        'year',
        'month',
        'day',
        'hour',
        'minute',
        'second',
      ];

      if (!this.is24Hour) p.push('period');
      return p;
    },
    
    boundForSelectedPart() {
      switch (this.selectedPart) {
        case 'day':    return 31;
        case 'month':  return 12;
        case 'year':   return 3000;
        case 'hour':   return this.is24Hour ? 23 : 12;
        case 'minute':
        case 'second': return 59;
      }
      console.warn("unbounded date part", this.selectedPart);
      return Infinity;
    },
    
    date() {
      let hour = this.hour;
      console.debug('hour raw: ', hour);
      if (!this.is24Hour && this.period == 'pm') {
        // We need to account for the afternoon period
        if (hour > 0 && hour < 12) {
          console.debug(`0 < hour > 12; increasing period ${hour} -> ${hour+12}}`);
          hour += 12;
        }
      }
      const now = new Date();

      console.log(`date(): ${this.year}-${this.month}-${this.day} ${hour}:${this.minute}:${this.second}`)

      return new Date(
        this.year || now.getFullYear(), 
        this.month !== undefined ? this.month-1 : now.getMonth(), 
        this.day || now.getDate(), 
        hour || 0, 
        this.minute || 0, 
        this.second || 0);
    }
  },
  
  watch: {
    value(v) {
      this.setInternalValueFromValue(v);
    },
    
    selectedPart(v) {
      this.selectedInputCount = 0;
    }
  },
  
  mounted() {
    this.setInternalValueFromValue(this.value);
  },
  
  methods: {
    
    padtime(value){
      if (!value) 
        return '00';

      return ("0" + value.toString()).slice(-2)
    },
    
    setInternalValueFromValue(v) {      
      if (typeof v === 'string') {
        v = new Date(v);
      }
      
      this.internalValue = v;

      if (v) {
        this.day    = v.getDate()
        this.month  = v.getMonth() + 1
        this.year   = v.getFullYear()
        this.minute = this.padtime(v.getMinutes())
        this.second = this.padtime(v.getSeconds())
        
        if (!this.is24Hour) {
          this.period = v.getHours() < 12 ? 'am' : 'pm';
          this.hour =  v.getHours() > 12 ? v.getHours() - 12 : v.getHours();
        }
        else {
          this.period = 'am';
          this.hour = v.getHours();
        }
      }
      else {
        // Null date
        this.day    = '';
        this.month  = '';
        this.year   = '';
        this.hour   = '';
        this.minute = '';
        this.second = '';
        this.period = '';
        
      }
    },
    
    valueDidChange() {
      console.debug('valueDidChange')
      this.$emit('input', this.date);
    },
    
    onFocus(e) {
      const elm = e.target;
      const found = !Object.keys(this.$refs).every((k) => {
        if (elm == this.$refs[k]) {
          this.selectedPart = k;
          return false;
        }
        return true;
      });

      if (!found) {
        console.warn("Unable to detect part being focused", e);
      }
    },
    
    blur() {
      const elm = this.$refs[this.selectedPart];
      elm.blur();
    },
    
    incrementSelection(howFar) {
      let i = this.parts.indexOf(this.selectedPart);
      if (i < 0 || i >= this.parts.length)
        return;
      
      i = (i + howFar) % this.parts.length;
      if (i < 0) i = this.parts.length -1;
      
      const partName = this.parts[i];
      const elm = this.$refs[partName];

      // debug
      if (!elm) {
        console.warn(`could not increment selection to part ${i} (have ${this.parts.length} parts)`)
        return;
      }
      elm.focus();
      elm.select();
    },
    
    selectNextPart() {
      this.incrementSelection(1);
    },
    
    selectPrevPart() {
      this.incrementSelection(-1);
    },
    
    selectNextPartOrBlur() {
      if (this.canSelectNextPart)
        this.selectNextPart();
      else
        this.blur();
    },
    
    keydownPeriod(e) {
      if (!e) return;        
      switch (e.key) {
        case 'a': 
        case 'A': 
        case 'ArrowUp':
          this.period = 'am'; this.valueDidChange(); e.preventDefault();
          break;
        case 'p': 
        case 'P': 
        case 'ArrowDown':
          this.period = 'pm'; this.valueDidChange(); e.preventDefault(); 
          break;
        case 'Tab':
          if (!e.shiftKey) {
            if (!this.canSelectNextPart) {
              return true; // Allow default (tab to next control)
            }
            // Can select next && we're not going backwards
            // It's not currently possible to reach here, as the 
            // period is the last element in this control.
            this.selectPrevPart();
            e.preventDefault();
            return false;
          }
          /* fall through */
        case 'ArrowLeft': this.selectPrevPart(); e.preventDefault(); break;
        default: 
          // suppress & ignore
          e.preventDefault();
          e.stopPropagation();
          break; 
      }
    },
    
    keydown(e) {
      if (!e) return;        
      if (e.metaKey) return;
      if (e.ctrlKey) return;
      
      const w = e.which;
      // Don't block tab key
      //
      if (w==9/*tab*/ || w==12/*return*/) {
        if (e.shiftKey) {
          // going backwards
          if (!this.canSelectPrevPart) {
            return true; // allow default (tab to a previous control outside this component)
          }
          this.selectPrevPart();
          e.preventDefault();
          return false;
        }
        else {
          // going forward
          if (!this.canSelectNextPart) {
            return true; // allow default (tab to the next control outside this component)
          }
          this.selectNextPart();
          e.preventDefault();
          return false;
        }
      }
      
      e.preventDefault();
      e.stopPropagation();
      
      // Arrow Keys
      //
      if (w == 37 /*left */) {
        this.selectPrevPart();
        return;
      }

      if (w == 39 /*right */) {
        this.selectNextPart()
        return;
      }
      
      if (w == 38 /* up */) {
        let bound = this.boundForSelectedPart;
        let inc = event.shiftKey ? 10 : 1
        let newValue = parseInt(this[this.selectedPart]) + inc;
        if (newValue <= bound) 
        {
          this[this.selectedPart] = newValue;
          this.valueDidChange();
        }
        return;
      }

      if (w == 40 /* down */) {
        const dec = event.shiftKey ? 10 : 1;
        if (this[this.selectedPart] - dec >= 0) {
          this[this.selectedPart] -= dec;
          this.valueDidChange();
        }
        return;
      }
      // '/' or ':'
      //
      if (e.key == ':' || e.key == '/') {
        this.selectNextPart();
        return;
      }
      
      // Delete
      if (w == 8 /*backspace*/) {
        const orig = this[this.selectedPart];
        console.debug(`delete ${orig} -> ${Math.floor(orig/10)}`)
      
        this[this.selectedPart] = Math.floor(orig / 10);
        this.valueDidChange();
        return;
      }
      
      // Check for digit
      //
      const digit = parseInt(e.key);
      if (isNaN(digit)) {
        // not a digit, not our problem.
        return;          
      }
      
      this.selectedInputCount++;

      //console.log(`key #${this.selectedInputCount} to ${this.selectedPart}`, digit);
      
      const wouldOverflow = digit * 10 > this.boundForSelectedPart;
      if (this.selectedInputCount == 1) {
        // first key since selection
        this[this.selectedPart] = digit;
        this.valueDidChange();
        
        if (wouldOverflow) {
          // Typed e.g. 6 in a box that can't support 60; we're done here
          this.selectNextPartOrBlur();
        }
      }
      else {
        // appending another value
        
        // typed the 2 in 12
        // Shift the current value by one magnitude and switch focus
        // to the next field.
        let newValue = this[this.selectedPart] * 10 + digit;
        if (newValue > this.boundForSelectedPart) {
          if (this.selectedPart == 'year') {
            // "19021 is out of bounds". 2-> 1902. 1-> 19021. Make it 21
            const newValueStr = newValue.toString();
            newValue = parseInt(newValueStr.slice(newValueStr.length -this.selectedInputCount));
          }
          else {
            console.debug(`append ${newValue} is out of bounds`);
            return;
          }
        }

        this[this.selectedPart] = newValue;
        this.valueDidChange();

        // Can we possibly add another digit?
        if (newValue * 10 > this.boundForSelectedPart)
          this.selectNextPartOrBlur();
      }
      
      
    },
    

  }
}
</script>