<template>
  <div class="race-event-details">
    <h1 v-if="raceEvent">
      Event Details
    </h1>
    <h1 v-else>
      New Event
    </h1>
    <form onsubmit="return false" class="race-event-options">
      <input ref="fileInput" style="display:none" type="file" @change="handleNewAvatarFile">
      
      <div class="avatar inset-box">
        <div class="race-event-avatar avatar-choosable">
          <img :src="raceEventImage" :alt="attributes.name || 'Race Event Image'">
          <button @click="askForAvatarFile">Choose</button>
        </div>
        <fieldset class="race-event-attrs">
          <div class="form-row">
            <label for="name">Name</label>
            <input v-model="attributes.name" @input="nameDidChange" type="text" name="name" autofocus>
          </div>

          <div class="form-row">
            <label for="name">Slug</label>
            <input v-model="attributes.slug" type="text" name="slug">
          </div>
        </fieldset>
      </div>
      
      <ul class="tabs">
        <li :class="{selected:selectedTab=='races'}" @click="selectTab('races')">
          Races
        </li>
        <li :class="{selected:selectedTab=='shirts'}" @click="selectTab('shirts')">
          Shirts
        </li>
        <li :class="{selected:selectedTab=='options'}" @click="selectTab('options')">
          Options
        </li>
      </ul>
      
      <fieldset v-show="selectedTab=='races'" class="inset-box races varlist-wrapper">
        <section class="scrollable">
          <table>
            <thead>
              <tr>
                <!-- t.integer "distance"
                    t.boolean "is_loop", default: false, null: false
                    t.boolean "is_timed", default: false, null: false
                    t.boolean "gun_time", default: true, null: false
                    t.datetime "start_time"
                    t.datetime "cutoff"
                    t.datetime "created_at", null: false
                    t.datetime "updated_at", null: false
                  end -->
                <th>Name</th>
                <th>Distance</th>
                <th>Looped?</th>
                <th>Format</th> <!--is_timed? ? fixed time : distance -->
                <th>Start Time</th>
                <th>Cutoff</th>
                <th />
              </tr>
            </thead>
            <tbody class="alternate-rows">
              <tr v-if="!races.length">
                <td class="no-content" colspan="100">
                  No Races
                </td>
              </tr>
              <tr v-for="race in races" v-else>
                <td class="race-name">
                  <span class="dot" :style="raceDotStyle(race)">&nbsp;</span>
                  {{ race.name }}
                </td>
                <td>{{ race.distance | mToDistance({units: true }) }}</td>
                <td :class="{looped: race.isLoop, pp: !race.isLoop}">
                  {{ race.isLoop ? 'Looped' : 'Point to Point' }}
                </td>
                <td>{{ race.isTimed ? 'Fixed Time' : 'Fixed Distance' }}</td>
                <td>
                  <span class="date">{{ race.startTime | formatDate('MMM Do \'YY', 'Unset') }}</span>
                  <span class="time">{{ race.startTime | formatDate("h:mm:ss a", ' ') }}</span>
                </td>
                <td>
                  <span class="date">{{ race.cutoff | formatDate('MMM Do \'YY', 'No Cutoff') }}</span>
                  <span class="time">{{ race.cutoff | formatDate("h:mm:ss a", ' ') }}</span>
                </td>
                <td class="control"> 
                  <button @click="editRace(race)" class="mini">
                    Edit
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <footer class="control">
          <button class="bar add" @click="editRace(null)">
            Add Race
          </button>
        </footer>
      </fieldset>
      
      <fieldset v-show="selectedTab=='shirts'" class="inset-box">
        <h2>Event Shirts</h2>
        <div class="form-row">
          <label>Shirt Cutoff</label>
          <datetime-picker v-model="attributes.shirtCutoff" ref="shirtdate"/>
          <button class="inline mini" @click="clearShirtCutoff">Clear</button>
        </div>
        <div class="note">Entering a shirt cutoff will prevent anyone registered after the cutoff date from receiving a shirt. This may result in more participants going without a shirt and retaining a larger shirt inventory after the event.</div>
        <div class="form-row">
          <label>Unisex</label>
          <toggle-switch v-model="attributes.unisex" />
        </div>
        
        <template v-if="attributes.unisex">
          <h4>Sizes</h4>
          <div class="form-row">
            <label>XS</label><input v-model="attributes.shirtCounts['xs']" type="number" step="1" class="mini" min="0">
            <label>S</label><input v-model="attributes.shirtCounts['s']" type="number" step="1" class="mini" min="0">
            <label>M</label><input v-model="attributes.shirtCounts['m']" type="number" step="1" class="mini" min="0">
            <label>L</label><input v-model="attributes.shirtCounts['l']" type="number" step="1" class="mini" min="0">
            <label>XL</label><input v-model="attributes.shirtCounts['xl']" type="number" step="1" class="mini" min="0">
            <label>2XL</label><input v-model="attributes.shirtCounts['xxl']" type="number" step="1" class="mini" min="0">
          </div>
        </template>
        <template v-else>
          <h4>Women's</h4>
          <div class="form-row">
            <label>XS</label><input v-model="attributes.shirtCounts['wxs']" type="number" step="1" class="mini" min="0">
            <label>S</label><input v-model="attributes.shirtCounts['ws']" type="number" step="1" class="mini" min="0">
            <label>M</label><input v-model="attributes.shirtCounts['wm']" type="number" step="1" class="mini" min="0">
            <label>L</label><input v-model="attributes.shirtCounts['wl']" type="number" step="1" class="mini" min="0">
            <label>XL</label><input v-model="attributes.shirtCounts['wxl']" type="number" step="1" class="mini" min="0">
            <label>2XL</label><input v-model="attributes.shirtCounts['wxxl']" type="number" step="1" class="mini" min="0">
          </div>
          <h4>Men's</h4>
          <div class="form-row">
            <label>XS</label><input v-model="attributes.shirtCounts['mxs']" type="number" step="1" class="mini" min="0">
            <label>S</label><input v-model="attributes.shirtCounts['ms']" type="number" step="1" class="mini" min="0">
            <label>M</label><input v-model="attributes.shirtCounts['mm']" type="number" step="1" class="mini" min="0">
            <label>L</label><input v-model="attributes.shirtCounts['ml']" type="number" step="1" class="mini" min="0">
            <label>XL</label><input v-model="attributes.shirtCounts['mxl']" type="number" step="1" class="mini" min="0">
            <label>2XL</label><input v-model="attributes.shirtCounts['mxxl']" type="number" step="1" class="mini" min="0">
          </div>
        </template>
        <h4>Youth</h4>
        <div class="form-row">
          <label>XS</label><input v-model="attributes.shirtCounts['yxs']" type="number" step="1" class="mini" min="0">
          <label>S</label><input v-model="attributes.shirtCounts['ys']" type="number" step="1" class="mini" min="0">
          <label>M</label><input v-model="attributes.shirtCounts['ym']" type="number" step="1" class="mini" min="0">
          <label>L</label><input v-model="attributes.shirtCounts['yl']" type="number" step="1" class="mini" min="0">
          <label>XL</label><input v-model="attributes.shirtCounts['yxl']" type="number" step="1" class="mini" min="0">
        </div>
      </fieldset>

      <fieldset v-show="selectedTab=='options'" class="inset-box">
        <div class="form-row">
          <label for="timezone_offset">Timezone</label>
          <select v-model="attributes.timezoneOffset" id="timezone_offset">
            <option value="-7">Arizona / MST</option>
            <option value="-6">Mountain (MDT Summer)</option>
            <option value="-5">Central (CDT Summer)</option>
            <option value="-4">Eastern (EDT Summer)</option>
          </select>
        </div>
        <div class="form-row">
          <label for="is_public">Show Public</label>
          <toggle-switch v-model="attributes.isPublic" form-id="is_public"></toggle-switch>
        </div>
        
      </fieldset>
      
      <fieldset v-show="selectedTab=='options'" class="inset-box">
        
        <div class="form-row">
          <label for="pos-ingress-type">Ingress Adapter</label>
          <select v-model="attributes.posIngressType" id="pos-ingress-type">
            <option :value="null">None</option>
            <option value="trackleadersxml">TrackLeaders XML</option>
            <option value="raceresult">RaceResult</option>
            <option value="opensplittime">OpenSplitTime</option>
            <option value="ultralive">UltraLive.net</option>
          </select>
        </div>

        <component 
          v-if="attributes.posIngressType" 
          :is="posIngressOptionsComponent" 
          :options="attributes.posIngressOpts"
          :race-event="raceEvent"
        />
        
        <div class="form-row">
          <label for="theme">Livestream Theme</label>
          <input type="text" v-model="attributes.theme" />
        </div>

      </fieldset>
    </form>
  </div>
</template>

<style lang="scss">
  .race-event-details {
    width: 100vw;
    max-width: 100%;
        
    .race-name { font-weight: bold;} 
    
    h2 {
      font-size: 18px;
      margin-bottom: 4px;
    }
    
    .race-event-options {
      .form-row:not(.inline) {
        max-width: 400px;
      }
      
      .form-row {
        label { width: 160px }
      }
      
      .inset-box.avatar {
        display: flex;
        
        .race-event-avatar {
          width: 128px;
          height: 128px;
          border-radius: 50%;
          display: block;
          background: var(--med-bgcolor);
          border: 1px solid var(--text-dim3-color);
          box-shadow: inset 0 0 7px var(--neg-color);
          position: relative;
          overflow: hidden;
          
          img {
            max-height: 70%;
            width: 70%;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
    
    .races {
      height: 20rem;
      margin-bottom: 0;
    }

    table {
      width: 100%;
      thead {
        tr {
          font-size: 14px;
//          background: rgba(0,0,0,0.15);
        }
        
        th {
          padding: 1ex 0;
          text-align: left;
          &:first-child { padding-left: 1em; }
        }
      }
      
      tbody {
        min-height: 10em;
                
        td {
          user-select: none;
          cursor: default;
          padding: 1ex 0;
          
          .dot {
            display: inline-block;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            vertical-align: middle;
            margin-left: 4px;
            margin-right: 4px;
          }
        }
        
        td.no-content {
          font-size: 18px;
          color: #555;
          text-align: center;
          padding: 1em;
        }
        
        .date { color: #777; margin-right: 4px;}
      }
    }

    .form-control {
      border-top: 1px solid rgba(0,0,0,0.2);
    }
    
  }
  
  
</style>

<script>
  import PioTrackleadersxml from "@/components/gps-ingress-options/pio-trackleadersxml.vue"
  import PioRaceresult from "@/components/gps-ingress-options/pio-raceresult.vue"
  import PioOpensplittime from "@/components/gps-ingress-options/pio-opensplittime.vue"
  import PioUltralive from "@/components/gps-ingress-options/pio-ultralive.vue"

  // const defaultAvatarSrc = require('@/assets/question.svg');
  import defaultAvatarSrc from '@/assets/question.svg'

  export default {
    name: 'RaceEventDetails',
    
    components: {
      PioTrackleadersxml,
      PioRaceresult,
      PioOpensplittime,
      PioUltralive
    },
    
    props: {
      raceEvent: {
        type: Object,
        required: false,
      }
    },
    
    data() {
      return {
        selectedTab: 'races',
        avatarFileBlobURL: undefined, // short, efficient uuid for showing in the browser without uploading
        avatarFileDataURL: undefined, // long, bulky data uri for posting if RaceEvent is being created.
        attributes: {
          shirtCounts: {},
          posIngressOpts: {},
        }
      }
    },
    
    computed: {
      raceEventImage() {
        if (this.avatarFileBlobURL)
          return this.avatarFileBlobURL;
        if (this.attributes.avatarUrl) {
          return this.attributes.avatarUrl;
        }
        
        return defaultAvatarSrc;
      },
      
      races() {
        // Denormalize if they're not in the cache.
        // raceEvent.races => [1,3,5,2]
        // this.$store.races => {1: {...}, 2: {...}}
        //
        /*
        const raceIDs = Object.keys(this.$store.state.races);
        return raceIDs.reduce((acc, id) => {
          let race = this.$store.state.races[id];
          if (race.raceEventId == this.attributes.id)
            acc.push(race);
          return acc;
        }, [])
        .sort((a, b) => b.distance - a.distance);
        */
        if (!this.$store.state.activeRaceEvent) {
          return [];
        }
        
        let races = this.$store.getters.entity('races', this.attributes.races)
        races.sort((a, b) => b.distance - a.distance);
        return races;
      },
      
      posIngressOptionsComponent() {
        if (!this.attributes.posIngressType) return null;

        // pio-trackleadersxml
        return 'pio-' + this.attributes.posIngressType;
      }
    },
    
    created() {
      // Create local copy of raceEvent to work with.
      this.attributes = Object.assign({}, this.raceEvent);
      if (!this.attributes.shirtCounts)
        this.attributes.shirtCounts = {}
      if (!this.attributes.posIngressOpts)
        this.attributes.posIngressOpts = {}
    },
        
    methods: {
      willShowInNavView() {
        this.$nextTick(() => {
          this.$modalWindow.setControlButtonsShown(true)
        });
      },
      
      selectTab(tabName) {
        this.selectedTab = tabName
      },
      
      editRace(race, e) {
        if (!this.$parent.$isNavigation) {
          console.error('Expected to be in navigation component');
        }
        
        // The RaceDetails view component is registered in the component that called
        // the ModalWindow, because the ModalWindow absorbs its parent's component
        // registrations.
        //
        if (!race) {
          race = {
            id: this.$store.getters.randomID(),
            raceEventId: this.raceEvent.id,
            name: 'New Race',
            splits: [],
            participants: []
          };
          
          // Have to commit to the store so we have something to denormalize :/
          let payload = {races: {}};
          payload.races[race.id] = race;
          this.$store.commit('commitEntities', payload);
        }
        
        // Show the race details view
        console.log ('pushing race-details', race);
        this.$parent.push('race-details', {race: race});
      },
      
      raceDotStyle(race) {
        return `background-color: ${race.color}`;
      },
      
      raceFormat(race) {
        
      },
      
      askForAvatarFile() {
        this.$refs.fileInput.click();
      },
      
      handleNewAvatarFile(e) {
        const f = e.target.files[0];

        // always show this. It's quick and from-memory.
        this.avatarFileBlobURL = URL.createObjectURL(f);
        
          
        if (this.attributes.id) {
          this.uploadFileForExistingRaceEvent(f);
        }
        else {
          // store to post for creation
          const fr = new FileReader();
          fr.onload = () => this.avatarFileDataURL = fr.result;
          fr.readAsDataURL(f);
        }
      },
      
      uploadFileForExistingRaceEvent(f) {
        const data = new FormData();
        data.append('race_event[logo]', f);

        this.$axios.put(`/race_events/${this.attributes.id}`, data)
        .catch((err) => {
          console.error("err:", err);
        })
        .then(r => {
          console.log("avatar result: ", r);
        })
        
      },
      
      clearShirtCutoff() {
        this.$set(this.attributes, 'shirtCutoff', null);
        this.$refs.shirtdate.$forceUpdate()
      },
      
      /* Slug Management*/
      nameDidChange(e) {
        // only modify if the slug was not intentionally set
        if (!this.raceEvent?.slug) {
          const year = this.races[0]?.startTime?.getFullYear() || new Date().getFullYear();
          this.attributes.slug = this.attributes.name.toLowerCase().replace(/[^\w\d\-]/g, '_') + '-'+ year;
        }
      }
      
    }
  }
</script>